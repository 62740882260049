<template>
  <b-card title="Ödeme Detayları">
    <b-row>

      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Firma"
          label-for="customer-name"
        >
          <b-form-input
            id="customer-name"
            :value="paymentData.customer_name"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Ödeme Yapan Kullanıcı"
          label-for="user-name"
        >
          <b-form-input
            id="user-name"
            :value="paymentData.user_name"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Ödeme Zamanı"
          label-for="payment-date"
        >
          <b-form-input
            id="payment-date"
            :value="$format_datetime(paymentData.created_at)"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Banka"
          label-for="bank-name"
        >
          <b-form-input
            id="bank-name"
            :value="paymentData.bank_name"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Banka İşlem Kodu"
          label-for="bank-transaction-code"
        >
          <b-form-input
            id="bank-transaction-code"
            :value="paymentData.bank_transaction_code"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Ödeme Tutarı"
          label-for="amount"
        >
          <b-form-input
            id="amount"
            :value="paymentData.amount+' TL'"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Sipariş Id"
          label-for="b2b_transaction_code"
        >
          <b-form-input
            id="b2b_transaction_code"
            :value="paymentData.b2b_transaction_code"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="İşlem Durumu"
          label-for="status"
        >
          <b-badge :variant="$staticParams.getPaymentStatusType(paymentData.status).variant">
            {{ $staticParams.getPaymentStatusType(paymentData.status).name }}
          </b-badge>

        </b-form-group>
      </b-col>
      <b-col
        v-if="paymentData.status==2"
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Banka Hata Kodu"
          label-for="error-code"
        >
          <b-form-input
            id="error-code"
            :value="paymentData.error_str"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="4"
      >
        <!-- readonly input -->
        <b-form-group
          label="Ödeme Türü"
          label-for="payment-type"
        >
          <b-badge
            v-if="paymentData.transaction_type==1"
            variant="primary"
          >
            Mail Order Ödemesi
          </b-badge>
          <b-link
            :to="{ name: 'order-detail', params: { id: paymentData.b2b_order_id }}"
            class="font-weight-bold"
          >
            <b-badge
              v-if="paymentData.transaction_type==2"
              variant="secondary"
            >
              Sipariş Ödemesi (Bağlı Olduğu Siparişe Gitmek İçin Tıklayın)
            </b-badge>
          </b-link>
        </b-form-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, VBToggle, BFormGroup, BFormInput, BBadge, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import paymentStoreModule from './paymentStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BFormInput,
    BCol,
    BFormGroup,
    BCard,
    BBadge,
    BLink,

  },
  data() {
    return {

    }
  },
  methods: {

  },
  setup() {
    const paymentData = ref(null)

    const INVOICE_APP_STORE_MODULE_NAME = 'app-payment'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, paymentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-payment/fetchSinglePayment', { id: router.currentRoute.params.id })
      .then(response => {
        paymentData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          paymentData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    return {
      paymentData,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
